import { Typography } from "antd";
import React from "react";
import "./index.scss";

const OurCommitment = () => {
  return (
    <section id="commitment" className="commitment-container">
      <Typography className="commitment-title">Our Commitment</Typography>
      <Typography className="commitment-description">
        At ACL , our commitment is centered on providing high-quality services
        with a focus on client satisfaction. We recognize the significance of
        our clients and prioritize their needs, tailoring our solutions to meet
        their specific requirements. With a customer-centric approach, we strive
        to build enduring partnerships that go beyond transactions.
      </Typography>
    </section>
  );
};

export default OurCommitment;
