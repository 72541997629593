import { Card } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React from "react";

const ServiceCard = ({ icon, title, content }) => {
  return (
    <Card className="our-service-card">
      <img src={icon} width="80px" alt="icon" />
      <Paragraph className="service-card-heading">{title}</Paragraph>
      <Paragraph className="service-card-content">{content}</Paragraph>
    </Card>
  );
};

export default ServiceCard;
