import React, { useState } from "react";
import "./index.scss";
import Paragraph from "antd/es/typography/Paragraph";
import { Button } from "antd";
import QuoteDialog from "../../../../Components/QuoteDialog/QuoteDialog";

const Banner = () => {
  const [openQuote, setOpenQuote] = useState(false);
  const handleOpen = () => setOpenQuote(true);
  const handleClose = () => setOpenQuote(false);
  return (
    <section className="banner-container" id="home">
      <Paragraph className="banner-heading white-text reset-margin">
        SERVING <br />
        <strong>GLOBAL DEMAND </strong>FOR A RELIABLE{" "}
        <strong> LOGISTICS</strong>
      </Paragraph>
      <Paragraph className="banner-description white-text ">
        Established in 2008, ACL is a distinguished logistics firm headquartered
        in Delhi, India. With strategically located offices in Mumbai and Delhi,
        we have successfully positioned ourselves as a reliable and efficient
        partner in the logistics industry.
      </Paragraph>
      <Button className="quote-btn" onClick={handleOpen}>
        Request for Quote
      </Button>
      <QuoteDialog open={openQuote} handleCancel={handleClose} />
    </section>
  );
};

export default Banner;
