import forwarding from "../Assets//Icons/forwarding.svg";
import clearance from "../Assets/Icons/ph_compass.svg";
import logistics from "../Assets/Icons/octicon_container-24.svg";
import transportation from "../Assets/Icons/la_ship.svg";
import deliver from "../Assets/Icons/icon-park-outline_delivery.svg";
import exchange from "../Assets/Icons/basil_exchange-outline.svg";
import id from "../Assets/Icons/user_id.svg";
import userCheck from "../Assets/Icons/user_arrow.svg";
import headphones from "../Assets/Icons/headphones.svg";
import globe from "../Assets/Icons/globe.svg";

export const ourServices = [
  {
    id: 1,
    icon: forwarding,
    title: "Freight Forwarding",
    content:
      "ACL specializes in seamless freight forwarding solutions, ensuring the swift and secure movement of goods across domestic and international borders.",
  },
  {
    id: 2,
    icon: clearance,
    title: "Custom Clearance",
    content:
      "We navigate the complexities of customs procedures with precision, ensuring that your shipments clear customs smoothly and comply with all regulatory requirements.",
  },
  {
    id: 3,
    icon: logistics,
    title: "Logistics Consulting",
    content:
      "Our team of seasoned experts provides comprehensive logistics consulting services, offering insights to optimize your supply chain and enhance overall efficiency.",
  },
  {
    id: 4,
    icon: transportation,
    title: "Transportation",
    content:
      "ACL ensures reliable transportation services, leveraging a robust network to deliver goods promptly and safely to their destinations.",
  },
  {
    id: 5,
    icon: deliver,
    title: "Delivery Duty",
    content:
      "We take care of delivery duties, ensuring that your shipments reach their intended recipients with the utmost care and adherence to delivery timelines.",
  },
  {
    id: 6,
    icon: exchange,
    title: "Import-Export & Trading",
    content:
      "Facilitating international trade, ACL is well-versed in import-export processes, creating opportunities for businesses to thrive in the global marketplace.",
  },
];

export const whyChooseUs = [
  {
    id: 1,
    icon: id,
    title: "Experience",
    content:
      "With over a decade of experience, ACL has a proven track record of delivering exceptional logistics solutions.",
  },
  {
    id: 2,
    icon: userCheck,
    title: "Expertise",
    content:
      "Our team comprises industry experts who bring extensive knowledge and skills to ensure the success of your logistics operations.",
  },
  {
    id: 3,
    icon: headphones,
    title: "Reliability",
    content:
      "We understand the importance of reliability in the logistics sector. ACL is committed to delivering on time, every time.",
  },
  {
    id: 4,
    icon: globe,
    title: "Global Reach",
    content:
      "Our presence in key locations, including Delhi and Mumbai, positions us as a reliable partner for both domestic and international logistics needs.",
  },
];

export const footerLinks = [
  {
    id: 1,
    name: "Quick Links",
    links: [
      {
        link: "/#home",
        label: "Home",
      },
      {
        link: "/#services",
        label: "Our Services",
      },
      {
        link: "/#commitment",
        label: "Our Commitments",
      },
      {
        link: "/#why-us",
        label: "Why Choose  Us?",
      },
    ],
  },
  {
    id: 2,
    name: "Social Media",
    links: [
      // {
      //   link: "",
      //   label: "Facebook",
      // },
      // {
      //   link: "",
      //   label: "Instagram",
      // },
      {
        link: "",
        label: "LinkedIn",
      },
    ],
  },
  // {
  //   id: 3,
  //   name: "Others",
  //   links: [
  //     {
  //       link: "",
  //       label: "Privacy Policy",
  //     },
  //     {
  //       link: "",
  //       label: "Terms and Conditions",
  //     },
  //     {
  //       link: "",
  //       label: "Contact Us",
  //     },
  //   ],
  // },
];

export const quoteFields = [
  {
    id: 1,
    name: "name",
    placeholder: "Your name",
    rules: [
      {
        required: true,
        message: "Please enter name",
      },
    ],
  },
  {
    id: 2,
    name: "email",
    placeholder: "Email ID",
    rules: [
      {
        required: true,
        message: "Please enter email",
      },
      {
        pattern:
          "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        message: "Please enter a valid email ID",
      },
    ],
  },
  {
    id: 3,
    name: "description",
    placeholder: "Project Description",
    rules: [
      {
        required: true,
        message: "Please enter description",
      },
    ],
  },
];
