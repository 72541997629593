import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import "./index.scss";
import line from "../../../../Assets/Images/line.svg";
import { Col, Row } from "antd";
import ServiceCard from "./ServiceCard";
import { ourServices } from "../../../../Utils/staticData";

const OurServices = () => {
  return (
    <section id="services" className="our-services-container">
      <div className="our-services-pre-heading-container">
        <img src={line} width="62px" alt="line" />
        <Paragraph className="our-services-pre-heading reset-margin">
          How we help
        </Paragraph>
        <img src={line} width="62px" alt="line" />
      </div>
      <Paragraph className="primary-heading reset-margin">
        Our Services
      </Paragraph>
      <Row className="service-card-container" gutter={[16, 16]}>
        {ourServices.map((service) => (
          <Col md={8}>
            <ServiceCard {...service} />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default OurServices;
