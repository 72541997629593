import { Card, Grid } from "antd";
import React from "react";
import "./index.css";
import logo from "../../../Assets/Images/logo.svg";
import MobileNavbar from "./MobileNavbar";
import DeskNavBar from "./DeskNavBar";
import Typography from "antd/es/typography/Typography";
import { useEffect } from "react";
import { isSticky } from "../../../Utils/helpers";

const NavBar = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  return (
    <Card
      className="navbar-container"
      // style={{ height: "80px" }}
      styles={{
        body: {
          paddingTop: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      }}
    >
      <div className="width-33 d-flex align-center">
        {" "}
        <img src={logo} width="58px" alt="logo" />
        <Typography className="logo-name">Airlift Container Lines</Typography>
      </div>
      {xs ? <MobileNavbar /> : <DeskNavBar />}
    </Card>
  );
};

export default NavBar;
