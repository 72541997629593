import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import Router from "./Config/routes";
import Store from "./Redux/store";

function App() {
  return (
    <Provider store={Store}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: "#ed1c24",
            },
          }}
        >
          <div>
            <Router />
          </div>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
