import { Typography } from "antd";
import React from "react";
// import WhatsApp from "../../../Assets/Images/whatsapp.svg";
import { Link } from "react-router-dom";

const DeskNavBar = () => {
  const { Text } = Typography;

  return (
    <div className="text-center nav-link-container">
      <Link to="/#home" activeClass="active" id="home-link" className="home">
        {" "}
        <Text>HOME</Text>
      </Link>
      <Link
        to="/#services"
        activeClass="active"
        id="services-link"
        className="services"
      >
        {" "}
        <Text className="l-15">SERVICES</Text>
      </Link>
      <Link
        to="/#commitment"
        activeClass="active"
        id="commitment-link"
        className="commitment"
      >
        {" "}
        <Text className="x-15">COMMITMENT</Text>
      </Link>

      <Link
        to="/#why-us"
        activeClass="active"
        id="why-us-link"
        className="why-us"
      >
        {" "}
        <Text>WHY US?</Text>
      </Link>
    </div>
  );
};

export default DeskNavBar;
