import React, { useEffect } from "react";
import Banner from "./Components/Banner";
import OurServices from "./Components/OurServices";
import OurCommitment from "./Components/OurCommitment";
import { WhyChooseUs } from "./Components/WhyChooseUs";
import { navItemHighlighter, scrollHighlighter } from "../../Utils/helpers";

const Home = () => {
  const href = window.location.href.substring(
    window.location.href.lastIndexOf("#") + 1
  );
  useEffect(() => {
    const element = document.getElementById(href);

    const elementOffset = element?.getBoundingClientRect().top + window.scrollY;
    const elementOffsetTest = element?.clientTop;
    //  + el.getBoundingClientRect().top
    console.log(
      " element?.getBoundingClientRect().top ",
      element?.getBoundingClientRect().top
    );
    const isAnotherPage = false;
    navItemHighlighter(isAnotherPage, href, elementOffset, elementOffsetTest);
    if (element) {
      window.scrollTo({
        top: elementOffset - 92,
        left: 0,
        behavior: "smooth",
      });
      // window.scrollTo(0, );
      // element.scrollIntoView({ behavior: "smooth" });
    }
  }, [href]);
  useEffect(() => {
    // sections.current = document.querySelectorAll("[data-section]");
    window.addEventListener("scroll", scrollHighlighter);

    return () => {
      window.removeEventListener("scroll", scrollHighlighter);
    };
  }, []);
  return (
    <div className="">
      <Banner />
      <OurServices />
      <OurCommitment />
      <WhyChooseUs />
    </div>
  );
};

export default Home;
