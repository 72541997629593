import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import logo from "../../../Assets/Images/logo.svg";
import phone from "../../../Assets/Icons/phone-line.svg";
import pin from "../../../Assets/Icons/map-pin-line.svg";

const FooterContact = ({ image, title, data }) => (
  <div className="footer-contact">
    <img src={image} alt="footer-contact-img" className="footer-contact-img" />
    <div>
      <Paragraph className="text-16">{title}:</Paragraph>
      <Paragraph>{data}</Paragraph>
    </div>
  </div>
);

const Column1 = () => {
  return (
    <div className="footer-column-1">
      <div className="footer-logo-container d-flex align-center">
        <img src={logo} alt="logo" width="58px" />
        <Paragraph className="footer-logo-name reset-margin">
          Airlift Container Lines
        </Paragraph>
      </div>

      {/* <Title level={2} className="lexend footer-logo typography-reset-margin">
        EW.
      </Title> */}
      <FooterContact image={phone} title="Phone" data="(91) 981-066-9858" />
      <FooterContact
        image={pin}
        title="Address"
        data="A-430, 4th floor, Ghrhitam Building Vashi, Mumbai - 400703"
      />
    </div>
  );
};

export default Column1;
