import React from "react";
import "./index.scss";
import Column1 from "./Column1";
import Column2 from "./Column2";
import Paragraph from "antd/es/typography/Paragraph";
import { Button } from "antd";
import { useState } from "react";
import QuoteDialog from "../../../Components/QuoteDialog/QuoteDialog";
const Footer = () => {
  const [openQuote, setOpenQuote] = useState(false);
  const handleOpen = () => setOpenQuote(true);
  const handleClose = () => setOpenQuote(false);
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <Column1 />
        <Column2 />
      </div>
      <div className="copyright-container">
        <Paragraph className="footer-copyright typography-reset-margin">
          ALL RIGHTS RESERVED
        </Paragraph>
        <Button className="quote-btn-footer" onClick={handleOpen}>
          Request for Quote
        </Button>
      </div>
      <QuoteDialog open={openQuote} handleCancel={handleClose} />
    </div>
  );
};

export default Footer;
