import Card from "antd/es/card/Card";
import Paragraph from "antd/es/typography/Paragraph";
import React from "react";

const ChoseCard = ({ icon, title, content }) => {
  return (
    <Card className="choose-card">
      <img src={icon} width="81px" alt="icon" />
      <div className="choose-card-right">
        <Paragraph className="chose-card-heading">{title}</Paragraph>
        <Paragraph className="chose-card-content reset-margin">
          {content}
        </Paragraph>
      </div>
    </Card>
  );
};

export default ChoseCard;
