import axios from "axios";

export const submitQuote = async ({ values, setLoading }) => {
  console.log("formData", values);
  try {
    const response = await axios.post(
      "https://acl-backend-prod.onrender.com/api/v1/home/contact-us",
      values
    );
    console.log("response", response);
    setLoading(false);
  } catch (err) {
    console.log("erer", err);
    setLoading(false);
  }
};
