import { Button, Form, Input, Modal } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React, { useState } from "react";
import Lottie from "react-lottie";
import "./index.scss";
import { quoteFields } from "../../Utils/staticData";
import { submitQuote } from "../../API/submitQuote";
import phone from "../../Assets/lotties/call.json";

const QuoteDialog = ({ open, handleOk, handleCancel }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    submitQuote({ values, setLoading });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: phone,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // const container = useRef();

  // useEffect(() => {
  //   Lottie.loadAnimation({
  //     name: `animation`,
  //     container: container.current,
  //     renderer: "svg",
  //     loop: true,
  //     // autoplay: false,
  //     animationData: phone,
  //   });
  // }, []);
  return (
    <Modal
      closable={false}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="dialog-container">
        <div className="get-quote-header-container d-flex align-center">
          {" "}
          {/* <div className="phone-lottie" ref={container} /> */}
          <Lottie options={defaultOptions} height={29} width={29} />
          <Paragraph className="get-quote-header reset-margin">
            Get a Quote
          </Paragraph>
        </div>

        <Form
          name="quote-form"
          layout="vertical"
          // initialValues={{ ...initialValues, remember: true }}
          onFinish={handleSubmit}
        >
          {quoteFields.map(({ name, placeholder, rules }) => (
            <Form.Item name={name} rules={rules}>
              <Input placeholder={placeholder} size="large" name={name} />
            </Form.Item>
          ))}
          <Button
            className="quote-submit-btn"
            htmlType="submit"
            loading={loading}
          >
            Get a Quote
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default QuoteDialog;
