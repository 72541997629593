import React from "react";
import "./index.scss";
import Paragraph from "antd/es/typography/Paragraph";
import { Col, Row } from "antd";
import ChoseCard from "./ChoseCard";
import { whyChooseUs } from "../../../../Utils/staticData";

export const WhyChooseUs = () => {
  return (
    <section id="why-us" className="why-choose-container">
      <Paragraph className="primary-heading reset-margin">
        Why Choose Us?
      </Paragraph>
      <Row gutter={[20, 20]} className="why-choose-cards-container">
        {whyChooseUs.map((data) => (
          <Col md={12}>
            <ChoseCard {...data} />
          </Col>
        ))}
      </Row>
    </section>
  );
};
