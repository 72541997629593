export const scrollToTop = () =>
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const navItemHighlighter = (
  isAnotherPage,
  href,
  offset,
  elementOffsetTest
) => {
  const homeLinkEl = document.getElementById("home-link");
  const servicesLinkEl = document.getElementById("services-link");
  const commitmentLinkEl = document.getElementById("commitment-link");
  const whyUsLinkEl = document.getElementById("why-us-link");

  // if (isAnotherPage) {
  //   console.log("is another page");
  //   homeLinkEl?.classList.remove("active");
  //   whatWeDoLinkEl?.classList.remove("active");
  //   faqLinkEl?.classList.remove("active");
  //   parivacyPolicyEl?.classList.add("active");
  // }
  // else {
  // parivacyPolicyEl?.classList.remove("active");
  if (href === "home") {
    homeLinkEl?.classList.add("active");
    servicesLinkEl?.classList.remove("active");
    commitmentLinkEl?.classList.remove("active");
    whyUsLinkEl?.classList.remove("active");
  } else if (href === "services") {
    homeLinkEl?.classList.remove("active");
    servicesLinkEl?.classList.add("active");
    commitmentLinkEl?.classList.remove("active");
    whyUsLinkEl?.classList.remove("active");
  } else if (href === "commitment") {
    homeLinkEl?.classList.remove("active");
    servicesLinkEl?.classList.remove("active");
    commitmentLinkEl?.classList.add("active");
    whyUsLinkEl?.classList.remove("active");
  } else if (href === "why-us") {
    homeLinkEl?.classList.remove("active");
    servicesLinkEl?.classList.remove("active");
    commitmentLinkEl?.classList.remove("active");
    whyUsLinkEl?.classList.add("active");
  }
  // }
};

export const scrollHighlighter = () => {
  const sections = document.querySelectorAll("section");
  const navLi = document.querySelectorAll(".nav-link-container a");
  window.onscroll = () => {
    var current = "";
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (window.scrollY >= sectionTop - 92) {
        current = section.getAttribute("id");
      }
    });
    navLi.forEach((li) => {
      li.classList.remove("active");
      if (li.classList.contains(current)) {
        li.classList.add("active");
      }
    });
  };
};

export const isSticky = (e) => {
  const header = document.querySelector(".navbar-container");
  const scrollTop = window.scrollY;
  scrollTop >= 100
    ? header?.classList.add("is-sticky")
    : header?.classList.remove("is-sticky");
};
